import {
  Box, Typography, Tooltip, Grid, Stack, Button, FormControlLabel, Checkbox, Toolbar, Paper, TextField,
} from '@mui/material';
import DatePicker, {Calendar, DateObject} from "react-multi-date-picker";
import { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../App';
import { useNavigate } from 'react-router-dom';
import pager2 from '../images/pager2.png';
import { DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import SortableList from './SortableList';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//********************************************************************************************************
let timer = false;
//********************************************************************************************************
function Pager(props) {
  const [ctx, setContext] = useContext(GlobalContext);
  let [rows, setRows] = useState(ctx.T.savedRows || {});
  let [state, setState] = useState({ today: new Date(), filterCBX: true, pagerNo: "", message: "", phoneType: "pagerAppPIN", tabIndex: 0 });
  const [person, setPerson] = useState({});
  const [service, setService] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [dept, setDept] = useState();
  const [deptList, setDeptList] = useState([]);
  const navigate = useNavigate();

  //======================================================
  async function fillDeptList() {
    let result = [], _;
    if(ctx.T.user.pagingOnly) [result, _] = await ctx.T.post("pagingGroup", { domain: "nyuhs.org" });
    else [result, _] = await ctx.T.post("pagingList");
    setDeptList(result);
    setPerson({});
  }
  //======================================================
  function fillServiceList(obj, index, ar) {
    try {
      setDept(obj);
      setDeptList(ar);
      setServiceList(filterServices(obj));
      setPerson({});
    } catch (err) { }
  }
  //======================================================
  function filterServices(dept) {
    if( ! state.filterCBX) return dept?.activities || [];
    let ar = [];
    try {
      let now = new Date();
      let dObj = new DateObject(state.today); dObj.hour = now.getHours(); dObj.minute = now.getMinutes();
      let doy = dObj.dayOfYear;
      let dow = dObj.weekDay.index;
      let dowName = dObj.weekDay.name;
      let nowMin = 60 * dObj.hour + dObj.minute;
      let isHoliday = dept?.holidays?.includes(doy) || false;


      dept?.activities.forEach(act => {
        if (!act[dowName]) return;
        if (isHoliday && act?.holidays === false) return;
        let time1Failed = false;
        try { time1Failed = failed(act.dailyDetails[dow].startTime, act.dailyDetails[dow].endTime, nowMin) } catch (err) { time1Failed = true; }
        let time2Failed = failed(act.startTime, act.endTime, nowMin);
        if (time1Failed && time2Failed) return;
        ar.push({ name: act.name, guid: act.guid });
      });
    } catch (err) { }
    return ar;

    function failed(startTime, endTime, nowMin) {
      let pastMN = false;
      try {
        let startMin = toMinutes(startTime);
        let endMin = toMinutes(endTime);
        if (endMin <= startMin) pastMN = true;
        if (startMin <= 0 || endMin <= 0) return false;
        if (pastMN) {
          if (nowMin > endMin && nowMin < startMin) return true;
        } else {
          if (nowMin < startMin) return true;
          if (nowMin > endMin) return true;
        }
      } catch (err) { console.log("Failed",err.message)}
      return false;
    }
    function toMinutes(time) {
      try {
        let hours = Number(time.slice(0, 2));
        let min = Number(time.slice(3));
        return 60* hours + min;
      } catch (err) { }
      return 0;
    }
  }
  //======================================================
  function handleChange(event) {
    try {
      if (event.toDate) {
        state.today = event.toDate();
        findOnCall(service);
      } else {
        event.preventDefault();
        if (event.target.type === "checkbox") {
          state[event.target.name] = !!!state[event.target.name];
          setPerson({});
          setServiceList(filterServices(dept));
        } else state[event.target.name] = event.target.value;
      }
      setState({ ...state });
    } catch (err) {}
  }
  //======================================================
  function clearPager() {
    state.pagerNo = "";
    state.message = "";
    delete state.name;
    setState({ ...state });
  }
  //======================================================
  async function saveMessage() {
    ctx.T.savedRows ??= {};
    let newMessage = {
      name: person.fullName,
      dept: dept?.info?.name,
      pagerNo: state.pagerNo,
      message: state.message,
      timeSent: new DateObject().format("MM/DD/YYYY HH:mm"),
      timeRead: "",
      action: state.phoneType,
    }

    let msgID = ctx.T.guid();
    ctx.T.savedRows[msgID] = newMessage;
    try {
      logMessage({ ...newMessage, sender: ctx.T.user.email, _id: String(msgID) });
      clearPager();
    } catch (err) { };
    setRows({ ...ctx.T.savedRows });
  }
  //======================================================
  async function sendMessage() {
    ctx.T.savedRows ??= {};
    let sendObj = {
      pagerNo: state.pagerNo,
      msg: state.message,
      action: "push"
    }
    let [_, msgID] = await ctx.T.formPost("https://pagerapp.amclogic.com/pager3.php", sendObj);

    if (Number(msgID) > 1) {      // 1 means that pager number was not found
      let newMessage = {
        name: person.fullName,
        dept: dept?.info?.name,
        pagerNo: state.pagerNo,
        // message: state.message.substring(0,50) + (state.message.length > 50 ? " ..." : ""),
        message: state.message,
        timeSent: new DateObject().format("MM/DD/YYYY HH:mm"),
        timeRead: ""
      }
      ctx.T.savedRows[msgID] = newMessage;
      try {
        logMessage({ ...newMessage, sender: ctx.T.user.email, _id: String(msgID) });
        clearPager();
      } catch (err) { };
    } else ctx.T.alert("Message failed to send.  Please check the pager number and try again")
    setRows({ ...ctx.T.savedRows });
  }
  //======================================================
  async function logMessage (msg) 
  {
    let [result, _] = await ctx.T.post("insertOne", { collection: "Message", doc: { firstStamp: Date.now(), ...msg } });
  }
  //======================================================
  async function showArchive() {
    navigate("/pager/messages");
  }
  //======================================================
  async function findOnCall(service) {
    setService(service);
    try {
      let dObj = new DateObject(state.today);
      let doy = dObj.dayOfYear;
      let [person, _] = await ctx.T.post("findOnCall", { id: dept._id, service: service.guid, doy: doy });
      setPerson(person);
      try {
        if (person.pagerAppPIN) {
          state.name = person.fullName + " " + (person.credentials || "");
          state.pagerNo = person.pagerAppPIN;
        } else {
          delete state.name;
          state.pagerNo = "";
        }
        setState({ ...state });
      } catch(err) {}
    } catch (err) { console.log(err.message)}
  }
  //======================================================
  function fillPagerNo(ev) {
    if (ctx.T.user.pagerOnly && ev.target.name != "pagerAppPIN") return;
    state.pagerNo = ev.target.value;
    state.phoneType = ev.target.name;
    if (ev.target.name === "pagerAppPIN") state.tabIndex = 0; else state.tabIndex = 1;
    setState({ ...state });
  }
  //======================================================
  function onTabChange(index,lastIndex,event) {
    state.phoneType = index === 0 ? "pagerAppPIN" : false;
    state.tabIndex = index;
    setState({ ...state });
  }
  //======================================================
  function newRow(label, dataSlug, minRows = 0, label2 = false, dataSlug2 = false) {
    return (<>
      <Grid item xs={3}>{label}</Grid>
      <Grid item xs={label2 === false ? 9 : 3}><TextField variant="outlined" size="small" multiline={minRows > 0 ? true : false} minRows={minRows}
        name={dataSlug} value={(person && person[dataSlug]) || ""}
        sx={{ width: "100%" }} onClick={ fillPagerNo} />
      </Grid>
      {label2 && <>
        <Grid item xs={dataSlug2===false?6:2} sx={{textAlign:'right',pr:0.5}}>{label2}</Grid>
        {dataSlug2 && <Grid item xs={4}><TextField variant="outlined" size="small" multiline={minRows > 0 ? true : false} minRows={minRows}
          name={dataSlug2} value={(person &&  person[dataSlug2]) || ""}
          sx={{ width: "100%" }}  onClick={ fillPagerNo} />
        </Grid>}
      </>}
    </>)
  }
  //======================================================
  useEffect(() => {
    fillDeptList();
    setState({...state});
  },[]);
  //======================================================
  
  return (<Box sx={{width:"100%",height:"100%"}}>
    <Stack direction="row" alignItems="center" sx={{ml:2,mt:1}}>
      <Box sx={{mr:2,whiteSpace: "nowrap"}}>Date:</Box>
      <DatePicker name="today" value={state.today} format="MM/DD/YYYY" onChange={handleChange} />
      <FormControlLabel control={<Checkbox name="filterCBX" value={true} onClick={handleChange} checked={state.filterCBX} />} label="Filter for time of day" sx={{ml:2}} />
    </Stack>

    <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" sx={{ p: 2 }}>
      <Stack direction="row" spacing={2}  sx={{width:500}}>
        <SortableList title="Departments" items={deptList} nameKey="info.name" classSel="w3-red" initialSelection={-1} onSelected={fillServiceList}
          sx={{ height: "70vh", width: 300, overflow: "auto", border: "2px solid", p: 0.5 }} />
        <SortableList title="Services" items={serviceList} /*onReset={setPerson}*/ nameKey="name"  classSel="w3-red" initialSelection={-1} onSelected={findOnCall}
          sx={{ height: "70vh", overflow: "auto", width: 200, border: "2px solid", p: 0.5 }} />
      </Stack>
      <Stack sx={{flexGrow:1}}>

        <Box sx={{ mx:3}}>
          <Grid container spacing={0} sx={{ px:1, border: 1, py:1}}>
            {newRow("Full Name", "fullName",0,"E-mail", "email")}
            {newRow("Cell Phone", "cellPhone", 0, "Office Phone", "officePhone")}
            {newRow("PagerApp PIN", "pagerAppPIN", 0, "Home Phone", "homePhone")}
            <Grid item xs={3}>Notes</Grid>
            <Grid item xs={9}><TextField variant="outlined" size="small" multiline minRows={2} value={(person?.howToCall || "") + " " + (person?.notes || "")}
              sx={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Box>
        <Box component="b" sx={{mx:3,mt:4}}>Instructions</Box>
        <TextField sx={{mx:3}} size="small" rows={6} multiline value={dept?.info?.notes || ""}/>
      </Stack>
    </Stack>

  </Box>)
}
//********************************************************************************************************
Pager.defaultProps = {
}

export default Pager;