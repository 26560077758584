import { useContext, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Redirect, useLocation, useNavigate, Link } from 'react-router-dom';
import { GlobalContext } from '../App';
import { Box, Tooltip, Stack } from '@mui/material'
import Topbar from './Topbar2.js';
import Footer from './Footer'
import Home from './Home.js';
import Login from './Login.js';
import Pager from './Pagershort.js';
import Schedules from './Schedules.js';
import User from './User.js';
import Messages from './PagerMessages.js';
import util from './Util.js';

const signedinMenu = [
  { icon: "home", click: () => {}, path: "/", title: "Home" },
  { icon: "bullhorn", click: () => { }, path: "/view", title: "View Published Schedule" },
  { icon: "folder-open", click: () => {}, path: "/schedules", title: "List of Schedules" },
  { icon: "gear", click: () => {}, path: "/user", title: "User Information" },
  { icon: "fax", click: () => {}, path: "/pager", title: "Send Pager Message" },
  // { icon: "envelope", click: () => {}, path: "/pager/messages", title: "View Archived Messages" },
  { icon: "sign-out", click: () => {}, path: "/logout", title: "Sign-out" },
];
//********************************************************************************************************
function Site2(props) {
  let location = useLocation();
  let navigate = useNavigate();
  let [menu, setMenu] = useState(signedinMenu);
  const [ht, setHt] = useState(document.body.clientHeight);
  let [ctx, setContext] = useContext(GlobalContext);
  ctx.U = util(ctx);

  //======================================================
  function autoLogout (minutes=60) {
    var idleTime = 0;
    var idleInterval = setInterval(timerIncrement, 60000); // 1 minute

    // Zero the idle timer on mouse movement.
    document.addEventListener("mousemove",function (e) { idleTime = 0;});
    document.addEventListener("keypress",function (e) { idleTime = 0;});

    function timerIncrement() {
      idleTime = idleTime + 1;
      if (idleTime > minutes) {
        navigate("/");
        window.location.reload();
      }
    }
  }
  //======================================================
  useEffect(() => {     // set Menu
    let menu = signedinMenu;
    setMenu(menu);
    autoLogout();
  });
  //======================================================
  useEffect(() => {     // set Menu
    setHt(document.body.clientHeight);
  },[document.body.clientHeight]);
  //======================================================

  let loggedIn = false, expired = true;
  try { loggedIn = ctx.T.user._id.length > 10; } catch (err) { }
  try { expired = ctx.T.user.expired; } catch (err) { }
  let framed = false;   if (ctx.T.user.pagingOnly) framed = true;


  return (<>
    {!ctx.T.user.hideMessaging && <Topbar colorClass2={ctx.bkSidebar2} colorSubtitle={"#2b5797"} />}
    <Stack sx={{height:"100vh"}}>
      {!framed && <Box className={ctx.bkSidebar2} sx={{ minHeight: window.outerHeight, position: "fixed", width: "60px"}}>
        <Box sx={{ textAlign: 'center'}} >
          {menu.filter(item=>item.path.indexOf("/pager") < 0 || ctx.T.user.canPage).map((item, nn) => <Link to={item.path} key={item.path} >
            <Tooltip title={item.title} placement="right">
              <i className={`fa fa-${ item.icon } menuButton ${ location.pathname === item.path ? "menuSelected" : "" }`}></i>
            </Tooltip>
          </Link>)}
        </Box>
      </Box>}
      <Stack sx={{flexGrow:1, mb:1, ml: framed ? 0 : "60px" }}>
        <Routes>
          <Route exact path="/" element={<Pager />} />
          <Route exact path="/messages" element={<Messages/>} />
        </Routes>
      </Stack>
      <Footer />
    </Stack>

    <style>{`
      .menuButton {
        color: white;
        font-size: 22pt;
        width: 50px;
        height: 40px;
        margin-top: 0.3rem;
        margin-left: 2px;
        margin-right: 2px;
        padding-top: 0.3rem;
        cursor: pointer;
        border-radius: 0.3rem;
      }
      .menuButton:hover {
        background-color: white;
        color: #2b5797;
      }
      .menuSelected {
        background-color: white;
        color: #2b5797;
      }
    `}</style>
  </>)
}
//********************************************************************************************************

export default Site2;