import { useContext, useState, useEffect } from 'react';
import {
  Box, Stack, Button, ButtonGroup, MenuItem, TextField, 
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { PostAdd,ContentCopy,Delete,Colorize } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../App';


const headerClass = "w3-flat-peter-river"
let objA = {};
let shortMenus = true;
let columns = [
  {
    field: 'scheduleName',
    headerName: 'Schedule',
    headerClassName: headerClass,
    width: 320,
    sortable: false,
    editable: false,
  },
  {
    field: 'stamp',
    headerName: 'Last Edited',
    headerClassName: headerClass,
    width: 120
  },
  {
    field: 'backups',
    headerName: 'Backups',
    headerClassName: headerClass,
    width: 130,
    renderCell: (params) => (
      params.row.id && <Box>{`${params.row.versions.length} available`}</Box>
    ),
  },
  {
    field: 'shared',
    headerName: 'Shared',
    headerClassName: headerClass,
    width: 130,
    renderCell: (params) => {
      let row = params.row;
      row.subowners ||= [];
      if (row.id == "") return "";
      else if(row.id) return (
        <Button onClick={() => params.row.editSharing(row)}>
          {row.subowners.length > 0 ? "shared with (" + row.subowners.length + ")": "not shared"}
        </Button>
      )
    }
  },
];

let currentRow = false;
//*********************************************************************************************************************
 function Schedules(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  let navigate = useNavigate();
  let [rows, setRows] = useState([{ id: "", scheduleName: "No Schedules on File" }]);

  //======================================================
  function handleChange(event) {
    event.preventDefault();
    try {
      // console.log(event.target);
      let obj = {};
      if (event.target.type === "checkbox") {
        obj[event.target.name] = !!! obj[event.target.name];
      } else obj[event.target.name] = event.target.value;
    } catch (err) { }
  }
  //======================================================
  function rowClick(obj) {
    currentRow = obj.row;
    ctx.T.myRole = myRole(obj);
  }
  //======================================================
  async function openSchedule(id) {
    if (typeof id !== "string") id = currentRow.id;
    if (!id || id.length < 6) {
      ctx.T.alert("Please select a schedule to proceed")
      return;
    }
    let [doc, _] = await ctx.T.post("getOneSchedule", { query: { _id: id } });
    if (ctx.T.isEmpty(doc)) return;
    ctx.schedule = doc;
    setContext({ ...ctx});
    navigate("/info");
  }
  //======================================================
  async function newSchedule() {
    let option = "blank";
    if (shortMenus) option = "blank"; else {
      option = await ctx.T.actionSheet("New Schedule", [
        { text: "New Blank Schedule", action: "blank" },
        { text: "Duplicate without Assignments", action: "withoutAssigments" },
        { text: "Duplicate Selected Schedule", action: "duplicate" },
        { text: "Cancel", action: "cancel" }
      ])
    };
    let result = {};
    switch (option) {
      case "blank": result = await newBlank(); break;
      case "withoutAssigments": result = await newDuplicate(true); break;
      case "duplicate": result = await newDuplicate(); break;
      default: break;
    };
    if (result._id) openSchedule(result._id); else scheduleList();
  }
  //======================================================
  async function newBlank() {
    
    await ctx.T.dlg({
      message: "Test",
      textOk:false,
      extraButtons: [{label:"Next"},{label:"Back"}]
    });

    let name = await ctx.T.prompt("Please enter the name for the schedule", "New Blank Schedule");
    if (name.trim().length < 3) {
      ctx.T.alert("Name should be at least 3 characters");
      return;
    }
    let [result, _] = await ctx.T.post("saveSchedule", { doc: { info: { name: name }} });
    return result;
  }
  //======================================================
  async function newDuplicate(withoutAssigments=false) {
    if (!currentRow.id || currentRow.id.length < 6) {
      ctx.T.alert("Please select a schedule to proceed")
      return;
    }
    let [result, _] = await ctx.T.post("makeDuplicate", { id: currentRow.id, withoutAssigments: withoutAssigments });
    return result;
  }
  //======================================================
  async function translate(obj) {
    // console.log(obj);
    let out = {}
    out.accountNo = obj.accountNo;
    out.sourceScheduleID = obj.scheduleID;
    out.name = obj.dates.dates_name;
    let d = obj.dates;
    out.info = {
      name: d.dates_name,
      slug: d.dates_slug,
      weekStarts: obj.weekStarts,
      accountNo: obj.accountNo,
      startDate: (new Date(d.dates_startdate)).toISOString().substring(0, 10),
      endDate: (new Date(d.dates_enddate)).toISOString().substring(0, 10),
      newYear: (new Date(d.dates_newyear)).toISOString().substring(0, 10),
      goodFriday: (new Date(d.dates_goodfriday)).toISOString().substring(0, 10),
      july4: (new Date(d.dates_independenceday)).toISOString().substring(0, 10),
      memorialDay: (new Date(d.dates_memday)).toISOString().substring(0, 10),
      laborDay: (new Date(d.dates_laborday)).toISOString().substring(0, 10),
      thanksgivingDay: (new Date(d.dates_thanksgiving)).toISOString().substring(0, 10),
      blackFriday: (new Date(d.dates_datg)).toISOString().substring(0, 10),
      christmas: (new Date(d.dates_christmas)).toISOString().substring(0, 10),
      custom1: d.dates_other.length > 0 ? (new Date(d.dates_others)).toISOString().substring(0, 10) : "",
      custom2: d.dates_other0.length > 0 ? (new Date(d.dates_others)).toISOString().substring(0, 10) : "",
      custom3: d.dates_other1.length > 0 ? (new Date(d.dates_others)).toISOString().substring(0, 10) : "",
      custom4: d.dates_other2.length > 0 ? (new Date(d.dates_others)).toISOString().substring(0, 10) : "",
      custom5: d.dates_other3.length > 0 ? (new Date(d.dates_others)).toISOString().substring(0, 10) : "",
      custom6: "",
      custom7: "",
      custom8: "",
    };

    out.activities = [];
    for (let name of obj.arActNames) {
      let i = obj[name];
      let o = {};
      o.timeLabel = i.activities_activitytime;
      o.startTime = "";
      o.endTime = "";
      o.name = name;
      try { o.guid = i.guid; } catch (err) { }; o.guid ||= ctx.T.guid();
      o.Monday = Boolean(Number(i.activities_monday));
      o.Tuesday = Boolean(Number(i.activities_tuesday));
      o.Wednesday = Boolean(Number(i.activities_wednesday));
      o.Thursday = Boolean(Number(i.activities_thursday));
      o.Friday = Boolean(Number(i.activities_friday));
      o.Saturday = Boolean(Number(i.activities_saturday));
      o.Sunday = Boolean(Number(i.activities_sunday));
      o.weeks = Array(52).fill(true);
      o.notes = i.activities_activitytime;
      out.activities.push(o);
    }

    out.staff = [];
    for (const key in obj.objP.listOptions) {
      let initials = obj.objP.listOptions[key];
      let p = obj.objP.data[key];
      let o = {};
      o.shortName = initials;
      o.name = lastName(p.personnel_name);
      o.fullName = p.personnel_name;
      o.cellPhone = "";
      o.officePhone = "";
      o.email = p.personnel_email;
      o.notes = "";
      o.canDo = {}; for (let aa of out.activities) o.canDo[aa.guid] = false;
      o.pagerAppPIN = "";
      o.vacations = [];
      o.employeeID = "";
      o.guid = ctx.T.guid();
      o.Monday = true; o.Tuesday = true; o.Wednesday = true; o.Thursday = true; o.Friday = true; o.Saturday = true; o.Sunday = true; 
      try {o[p.personnel_nodaycall1] = false} catch(err) {}
      try { o[p.personnel_nodaycall2] = false } catch (err) { }
      try {
        for (let d of p.personnel_vacations) o.vacations.push((new Date(d)).getTime());
      } catch (err) { }
      for (const key2 in p) {
        let activity = p[key2];
        try {
          if (key2.indexOf("personnel_myactivities") !== 0) continue;
          if (activity.length > 0) o.canDo[activityGUID(activity)] = true;
        } catch (err) { }
      };
      out.staff.push(o);
    };

    out.assignments = {};
    let a = obj.assignments;
    for (const actName in a) {
      let ag = activityGUID(actName);
      out.assignments[ag] = {}
      for (const doy in a[actName]) {
        let sg = staffGUID(a[actName][doy]); 
        out.assignments[ag][doy] = sg;
      }
    }


    // console.log(out);
    save();

    function activityGUID(name) {
      for (const act of out.activities) if (act.name === name) return act.guid;
      return undefined;
    }
    function staffGUID(shortName) {
      for (const person of out.staff) if (person.shortName === shortName) return person.guid;
      return undefined;
    }
    function lastName(fullName) {
      let newName = fullName;
      do {
        fullName = newName;
        newName = fullName.replace(/PhD|MBA|NP$|N\.P\.|MD$|M\.D\.$| $|,$/i, "");
      } while (newName !== fullName);
      return fullName.substring(fullName.lastIndexOf(" ") + 1)
    }
    async function save() {
      let [result,_] = await ctx.T.post("saveSchedule", { query: { sourceScheduleID: out.sourceScheduleID }, doc: out });
      if (!result.ok) ctx.T.alert("Data could not be saved", "Saving"); else {
        ctx.T.alert("Data successfully imported and saved", "Import")
        scheduleList();
      }
    }
  }
  //======================================================
  async function importFromOldFile(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload=function(){
      objA = JSON.parse(reader.result);
      translate(objA);
    }
    reader.readAsText(file);
  }
  //======================================================
   function myRole(obj) {
     let oldAccess = {edit:1,switch:1,delete:0,publish:1,view:1,grant:0,reporting:1,paging:0};
     let fullAccess = {edit:1,switch:1,delete:1,publish:1,view:1,grant:1,reporting:1,paging:1};
     let noAccess = {edit:0,switch:0,delete:0,publish:0,view:0,grant:0,reporting:0,paging:0};

     if (obj.row.myID === obj.row.ownerID) return fullAccess;
     let role = obj.row?.roles?.find((x) => x.email.toLowerCase() === ctx.T.user.email.toLowerCase());
     if (role) return role;
     if (obj.row.subowners.includes(ctx.T.user.email)) return oldAccess;
     return noAccess;
  }
  //======================================================
  async function grant(dataIn={}) {
    if (!currentRow.id || currentRow.id.length < 6) {
      await ctx.T.alert("Please select a schedule to proceed")
      return;
    }
    editSharing(currentRow);
  }
  //======================================================
  async function grantAccess(dataIn={}) {
    if (!ctx.T.myRole?.grant) { ctx.T.alert("You do not have access to delegate your privileges.  \nPlease contact your administrator if you need this access"); return; }
    // if (currentRow.ownerID !== currentRow.myID) {
    //   await ctx.T.alert("Only the original owner of the schedule may grant access to others","Cannot Grant Access")
    //   return;
    // }
    let role = await ctx.U.delegate(currentRow.scheduleName, dataIn);
    if (!role) return;
    if (role === "remove" && ctx.T.isEmail(dataIn?.email) ) {
      let [result, _] = await ctx.T.post("saveRole", { id: currentRow.id, action: "remove", role: dataIn }); 
      if (result.ok) {
        ctx.T.alert(`Access for '${ dataIn.email }' has been revoked. `, "Success");
        let [x,y] = await ctx.T.post("revokeAccess", { id: currentRow.id, email: dataIn.email }); // removes from the old system
      }
    } else {
      if (! role || ! role.email) return;
      let [result, _] = await ctx.T.post("saveRole", { id: currentRow.id, action: "save", role: role }); 
      if (result.ok) {
        ctx.T.alert(`Access for '${ role.email }' has been updated as requested`, "Success");
        await ctx.T.post("revokeAccess", { id: currentRow.id, email: role.email }); // removes from the old system
      }
    }
    scheduleList();
  }
  //======================================================
  async function editSharing(row) {
    currentRow = row;
    let ar = row.subowners.map((email, nn) => {
      return { text: email, action: email };
    });
    if (ar.length <= 0) {
      grantAccess();
      return;
    }
    ar.push({ text: "Share with Another", action: "Add New", class:"w3-light-blue" });
    ar.push({ text: "Cancel", action: "", class:"w3-red" });
    let email = await ctx.T.actionSheet("Alter Access / Grant Access", ar);
    if (!email) return;

    if (email == "Add New") { grantAccess(); return; }

    let [result, _] = await ctx.T.post("getRoles", { id: row.id });
    let role = {email:email, edit:1};
    try {
      result.roles.forEach(x => {
        if (x.email != email) return;
        role = x; 
      });
    } catch (err) { console.log(err.message) }

    grantAccess(role);
    // scheduleList();
  }
  //======================================================
  async function deleteSchedule() {
    if (!currentRow.id || currentRow.id.length < 6) {
      ctx.T.alert("Please select a schedule to proceed")
      return;
    }
    if (!ctx.T.myRole.delete) { ctx.T.alert("You do not have access to delete this schedule.  \nPlease contact your administrator if you need this access"); return; }
    let yes = await ctx.T.confirm(`Do you want to permanently delete '${currentRow.scheduleName}'`);
    if (!yes) return;
    let [result, _] = await ctx.T.post("deleteSchedule", { _id: currentRow.id, backupComment: `Deleted ${ctx.T.shortDate()}` });
    console.log(result, _);
    scheduleList();
  }
  //======================================================
  async function scheduleList() {
    ctx.schedule = {};
    let rows = [];
    let [doc, _] = await ctx.T.post("scheduleList");
    try {
      doc.forEach(async (elem, nn) => {
        elem.info ||= {}; elem.info.name ||= "";
        let subowners = elem.subowners || [];  try {
          elem.roles.map(role => subowners.push(role.email));
        } catch (err) { }
        let isOwner = elem.ownerID == ctx.T.user._id; if ((!isOwner)) try {
          elem.roles.map(role => { if(role.email == ctx.T.user.email && role.grant) isOwner=true});
        } catch (err) { }
        rows.push({
          stamp: ctx.T.shortDate(elem.stamp),
          scheduleName: elem.info.name,
          id: elem._id,
          ownerID: elem.ownerID,
          isOwner: isOwner,
          myID: ctx.T.user._id,
          subowners: subowners,
          versions: elem.backups,
          roles: elem.roles,
          backupComment: elem.backupComment,
          editSharing: editSharing,
        });
      });
    } catch (err) { }

    if (rows.length <= 0) {
      rows = [{ id: "", scheduleName: "No Schedules on File" }];
      shortMenus = true;
    } else shortMenus = false;
    setRows(rows);
    return rows;
  }
  //======================================================
  // async function changeVersion(row) {
  //   let ar = row.versions.map((elem, nn) => {
  //     elem.backupComment ||= ctx.T.dateTime(elem.stamp);
  //     return { text: nn===0 ? "Latest Version" : elem.backupComment, action: elem.id };
  //   });
  //   ar.push({text: "Cancel", action: ""});
  //   let id = await ctx.T.actionSheet("Open A Backup Version  ", ar);
  //   if(id) openSchedule(id);
  // }
  //======================================================
  useEffect(() => {
    scheduleList();
   }, []);
  //======================================================
  const buttons = [
    <Button key="one"  onClick={deleteSchedule}>Delete</Button>,
    <Button key="two"  onClick={newSchedule}>New Schedule</Button>,
    <Button key="threee" onClick={openSchedule}>Open</Button>,
    // <Button key="three" onClick={makeBackup}>Create Backup</Button>,
    // <Button key="seven" onClick={()=>grant()}>Grant Access</Button>,
    // <Button key="eight" onClick={deleteSchedule}>Delete</Button>,
  ]
  

  return (<>
    <Box component="h2" sx={{ p:2}}>My Schedules</Box>
    <Box sx={{ width: 702, m: "auto", '& .pointer':{cursor:"pointer"} }}>
      {shortMenus && <Stack direction="row" justifyContent="right"><ButtonGroup color="secondary" variant="text">
        <Button key="one"  onClick={newSchedule}>New Schedule</Button>
        {/* <Button component="label">Import<input hidden accept="*.json" type="file" onChange={importFromOldFile} /></Button> */}
      </ButtonGroup></Stack>}
      {/* {!shortMenus && <Stack direction="row" justifyContent="right"><Button component="label">Import<input hidden accept="*.json" type="file" onChange={importFromOldFile} /></Button></Stack>} */}
      {!shortMenus && <Stack direction="row" justifyContent="right"><ButtonGroup color="secondary" variant="text">{buttons}</ButtonGroup></Stack>}
      <DataGrid sx={{ mt: 1 }}
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={15}
        density="compact"
        onRowClick={rowClick}
        onRowDoubleClick={openSchedule}
        getCellClassName={()=>"pointer"}
      />
    </Box>
  </>
  );
}
//*************************************************************************************************************************

export default Schedules;