import { useContext, useState, useEffect } from 'react';
import { FluentProvider, webLightTheme, Button, Label, RadioGroup, Radio } from "@fluentui/react-components";
import { BrowserRouter, Routes, Route, Redirect, useLocation, useNavigate, Link } from 'react-router-dom';

//********************************************************************************************************
function Site3(props) {

  //======================================================

  return (
    <FluentProvider theme={webLightTheme}>
      <center>
      <Button appearance="primary">Hello Fluent UI React</Button>
      <div >
        <Label id={"labelId"}>Favorite Fruit</Label>
        <RadioGroup {...props} aria-labelledby={"labelId"}>
          <Radio value="apple" label="Apple" />
          <Radio value="pear" label="Pear" />
          <Radio value="banana" label="Banana" />
          <Radio value="orange" label="Orange" />
        </RadioGroup>
        </div>  
      </center>  
      </FluentProvider>
  )
}
//********************************************************************************************************

export default Site3;