import { createContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom';
import { red,blue,blueGrey } from '@mui/material/colors';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Site from './components/Site.js';
import Pager from './components/Pager.js';
import Site2 from './components/Site2.js';
import Site3 from './components/Site3.js';
import tools from './components/Tools';


tools.server = "callschedule.us";

//************************************************************************************************************
const theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
      // main: red[300],
      // main: blueGrey[400],
    }
  }
});
//************************************************************************************************************
let initialCTX = {
  T: tools,
  bkTop: "w3-metro-dark-blue",
  bkSidebar: "w3-metro-light-blue",
  bkSidebar2: "w3-metro-dark-blue",
  schedule: {},
  theme: theme,
};
export let GlobalContext = createContext();


function ScrollToTop() {      // when a page is reloaded it scrolls to top
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App(props) {
  return (<>
    <Router>
      <ScrollToTop />
      <GlobalContext.Provider value={useState({ ...initialCTX, data: props })}>
          <CssBaseline />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/pager/*" element={<Site2 />} />
            <Route path="/fluent/*" element={<Site3 />} />
            <Route path="/*" element={<Site {...props} />} />
          </Routes>
        </ThemeProvider>
      </GlobalContext.Provider>
    </Router>
  </>)
}

export default App;

