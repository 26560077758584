import { Box, Link, Stack, FormControl, Checkbox, Switch, FormControlLabel, Button, RadioGroup, Container, FormGroup, TextField, Radio, MenuItem } from '@mui/material';
import DatePicker, {Calendar, DateObject} from "react-multi-date-picker";
import { useState } from 'react';
import XLSX from 'xlsx-js-style';

function _U(ctx) { _U.ctx = ctx; return _U; };
let weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
let weekDays3 = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//********************************************************************************************************
_U.saveSchedule = async function (confirm=true) {
  let ctx = _U.ctx;
  if (!ctx.T.myRole.edit) { ctx.T.alert("You do not have access to edit/save the schedule.  \nPlease contact your administrator if you need this access"); return; }
  
  ctx.schedule.backupComment = "";  // It is no longer a backup
  ctx.schedule.vacations = _U.calcVacations();
  ctx.schedule.holidays = _U.calcHolidays();
  ctx.schedule.assignments = { ...ctx.schedule.assignments };   // work-around to convert assignments from array to object
  if (ctx.schedule.parentID) {                                  // Saving a backup as active
    delete ctx.schedule.parentID; 
    ctx.schedule.info.name += ctx.schedule.backupComment;
    delete ctx.schedule.backupComment; 
  }
  let [result, error] = await ctx.T.post("saveSchedule", { _id: ctx.schedule._id, doc: ctx.schedule });
  if (confirm) {
    if (result.ok) ctx.T.alert("Data Saved", "Success"); else {
      if (error !== "cannotOverwrite") ctx.T.alert("Data could not be saved", "General Error");
    }
  }
  if (result.ok) return true; else return false;
}
//********************************************************************************************************
_U.calcVacations = function () {
  let ctx = _U.ctx;
  let out = {};
  let start = ctx.T.dateObj(ctx.schedule.info.startDate).valueOf();
  let end = ctx.T.dateObj(ctx.schedule.info.endDate).valueOf();
  for (let p of ctx.schedule.staff) {
    let days = p.vacations.map(ms => {
      if (ms < start || ms > end) return false;
      let dateObject = new DateObject(ms);
      return ctx.T.doy(dateObject.format("YYYY-MM-DD"));
    });
    for (let doy of days) {
      if (doy === false) continue;
      out[doy] ??= [];
      out[doy].push(p.guid);
    }
  }
  return out;

  // function dt(ms) {
  //   let d = new DateObject(ms);
  //   return d.format("M/D/YY");
  // }
}
//********************************************************************************************************
_U.calcHolidays = function () {
  let ctx = _U.ctx;
  let doy = 0;
  let holidays = [];
  try {
    doy = dayOfYear(ctx.schedule.info.newYear); if (doy > 0) holidays.push(doy);
    doy = dayOfYear(ctx.schedule.info.goodFriday); if (doy > 0) holidays.push(doy);
    doy = dayOfYear(ctx.schedule.info.memorialDay); if (doy > 0) holidays.push(doy);
    doy = dayOfYear(ctx.schedule.info.july4); if (doy > 0) holidays.push(doy);
    doy = dayOfYear(ctx.schedule.info.laborDay); if (doy > 0) holidays.push(doy);
    doy = dayOfYear(ctx.schedule.info.thanksgivingDay); if (doy > 0) holidays.push(doy);
    doy = dayOfYear(ctx.schedule.info.blackFriday); if (doy > 0) holidays.push(doy);
    doy = dayOfYear(ctx.schedule.info.christmas); if (doy > 0) holidays.push(doy);
    for (let nn = 1; nn <= 8; nn++) { doy = dayOfYear(ctx.schedule.info["custom" + nn]); if (doy > 0) holidays.push(doy); }
  } catch (err) { }
  return holidays;

  function dayOfYear(date) {									// Jan 1 is day 1 not zero
    if (typeof date != "object") date = new Date(date+"T00:00:00");
    var onejan = new Date(date.getFullYear(),0,1);
    return Math.ceil((date - onejan) / 86400000)+1;
  }
}
//********************************************************************************************************
_U.makeBackup = async function() {
  let ctx = _U.ctx;
  let comment = await ctx.T.prompt("Comment Regarding this Backup", "Make Backup");
  if (comment === false) return;
  let [result, _] = await ctx.T.post("makeBackup", { _id: ctx.schedule._id, backupComment: comment });
    if (result?.n == 1) ctx.T.alert(`Backup '${ comment }' successfully created`); else ctx.T.alert("Backup Failed");
  }
//********************************************************************************************************
_U.loadBackup = async function () {
  let ctx = _U.ctx;
  let ar = [], parentID = ctx.schedule.parentID || String(ctx.schedule._id);
  let [result, _] = await ctx.T.post("loadBackup", { parentID: parentID });

  try {
    for (let elem of result) {
      let text = ctx.T.dateTime(elem.stamp) + (elem?.backupComment ? ", " + elem?.backupComment : "");
      ar.push( { text: text, action: elem._id });
    }
  } catch (err) { }
  if (ar.length > 0) {
    ar.push({ text: "Cancel", action: "" });
    let id = await ctx.T.actionSheet("Open A Backup Version  ", ar);
    return id;
  }
  ctx.T.alert("No backup found");
}
//********************************************************************************************************
_U.unpublish = async function () {
  let ctx = _U.ctx;
  if (!ctx.T.myRole.publish) { ctx.T.alert("You do not have access to publish or un-publish the schedule.  \nPlease contact your administrator if you need this access"); return; }

  let yes = await ctx.T.confirm(`Do you want to remove '${ctx.schedule.info.name}' from publication?`);
  if (!yes) return;
  let [result, _] = await ctx.T.post("unpublish", { _id: ctx.schedule._id });
  if (result.ok) ctx.T.alert(`"${ctx?.schedule?.info?.name}" has been removed from publication`); else ctx.T.alert("Request failed.  Please try again later");
}
//********************************************************************************************************
_U.publish = async function () {
  let ctx = _U.ctx;
  if (!ctx.T.myRole.publish) { ctx.T.alert("You do not have access to publish the schedule.  \nPlease contact your administrator if you need this access"); return; }

  let saved = await _U.saveSchedule(false);
  if (!saved) { ctx.T.alert("Cannot publish because data could not be saved"); return; }
  
  let [result, _] = await ctx.T.post("publish", { _id: ctx.schedule._id });
  if (result.slug) {
    ctx.T.alert(`
    Credentials for iPhone App Access:<br>
    <table style="background-color:yellow;margin:auto">
    <tr><td>Account No</td><td>&nbsp;&nbsp;&nbsp;</td><td>${result.accountNo}</td></tr>
    <tr><td>Schedule Name</td><td></td><td>${result.slug}</td></tr>
    ${result.securityCode ? "<tr><td>Security Code</td><td></td><td>" + result.securityCode + "</td></tr>" : ""}
    </table>
    <p>(App is available in Apple AppStore as "Call Schedule" by AMC Logic)</p>
    <center>Link for web access: <a target="_blank" href="https://callschedule.us/view/${result.accountNo}/${result.slug}">https://callschedule.us/view/${result.accountNo}/${result.slug}</a></center>
    `, "Please Share with Your Users");
    if( ! ctx.T.isBlank(result.epicID)) try {
      var response = await fetch("https://webapps.nyuhs.org/CallSchedule/CallSchedule.aspx", {
        method: "POST",
        body: `https://callschedule.us/export/${result.slug}/${result.accountNo}/${result.epicID}`,
        cache: "no-cache",
        mode: "cors",
      });
      var text = await response.text();
      // console.log(text);
    } catch (err) { }
  } else {
    ctx.T.alert("Could not published the schedule", "Error");
  }
}
//********************************************************************************************************
_U.personFromGUID = function(guid) {
  let ctx = _U.ctx;
  try { for (let p of ctx.schedule.staff) if (p.guid == guid) return p; } catch(err){}
  return false;
}
//********************************************************************************************************
_U.activityFromGUID = function(guid) {
  let ctx = _U.ctx;
  for (let a of ctx.schedule.activities) if (a.guid == guid) return a;
  return false;
}
//********************************************************************************************************
_U.VacationPopup = function(props) {
  let ctx = _U.ctx;
  let year = new Date().getFullYear();
  try { year = ctx.schedule.info.startDate.substring(0, 4); } catch(err) {}

  function pop(event) {
    let vacstr = "";
    props.away.map((a,i) => vacstr += (i+1)+".  " + _U.personFromGUID(a).fullName + "<br/>");
    if(vacstr.length > 0) ctx.T.alert(vacstr,`Vacations for ${ctx.T.dateFromDoy(props.doy,year)[1]}`);
  }

  return (<>
    <Box>
      {props.away.length > 0 ? <Link sx={{cursor:"pointer"}} onClick={pop}>{props.away.length + " away"}</Link> : "--"}

    </Box>
  </>);
}
//********************************************************************************************************
_U.getStats = function () {
  let ctx = _U.ctx;
  let startDate = new DateObject(ctx.schedule.info.startDate);
  let endDate = new DateObject(ctx.schedule.info.endDate);
  let counts = _U.countCalls(startDate, endDate);

  const worksheet = XLSX.utils.json_to_sheet(counts.byWeekday,{skipHeader:true});
  let html = XLSX.utils.sheet_to_html(worksheet,{id:"statsTable"});
  return html;

}
//********************************************************************************************************
_U.report = async function () {
  let ctx = _U.ctx;
  if (!ctx.T.myRole.reporting) { ctx.T.alert("You do not have access to run reports.  \nPlease contact your administrator if you need this access"); return; }

  let options = {};
  let formatOut = "YYYY-MM-DD";
  let formatShow = "MM/DD/YYYY";
  
  let props = {
    textOk: "Create Report",
    title: "Choose Report",
    // minHeight: 300,
    handleOk: () => options = ctx.T.readForm("formElem"),
    message: <>
      <form id="formElem">
        <Stack direction="row" m={2}>
          <Box sx={{mr:2,whiteSpace: "nowrap"}}>Start Date</Box>
          <DatePicker name="startDate" value={ctx.T.date(ctx.schedule.info.startDate)} format={formatShow} portal={true} zIndex={10000} />
          <Box sx={{mx:2,whiteSpace: "nowrap"}}>End Date</Box>
          <DatePicker name="endDate" value={ctx.T.date(ctx.schedule.info.endDate)} format={formatShow} portal={true} zIndex={10000} />
        </Stack>
        <Box sx={{ mx: 5 }}>
          <RadioGroup name="reportType" defaultValue="counts" >
            <FormControlLabel value="counts" control={<Radio />} label="Shift Totals by Staff" />
            <FormControlLabel value="countsbyWeekday" control={<Radio />} label="Shift Totals by Staff and Weekday" />
            <FormControlLabel value="shifts" control={<Radio />} label="Payment Report" />
          </RadioGroup>
        </Box>
      </form>
      </>
  }
  let ok = await ctx.T.dlg(props);
  options.startDate = ctx.T.formatDate(options.startDate, formatOut, formatShow);
  options.endDate = ctx.T.formatDate(options.endDate, formatOut, formatShow);

  if (ok) {
    switch (options.reportType) {
      case "counts": createExcel(getCounts(),"Work Report"); break;
      case "countsbyWeekday": createExcel(getWeekdayCounts(), "Shift Totals by Weekday",{skipHeader:true}); break;
      case "shifts": createExcel(getShifts(),"Payment Report"); break;
      default: break;
    }
  }

  //=========================================================
  function getWeekdayCounts() {
    let counts = _U.countCalls(options.startDate, options.endDate);
    // console.log(counts.byWeekday);
    return counts.byWeekday || [];
  }
  //=========================================================
  function getCounts() {
  	let counts = _U.countCalls(options.startDate,options.endDate);
    return counts.byPerson || [];
  }
  //=========================================================
  function getShifts() {
    let counts = _U.countCalls(options.startDate, options.endDate);
    let arSheet = [];
    for (let pguid in counts.payments) {
      // let person = _U.personFromGUID(pguid);
      // if (!person) continue;
      let ar = counts.payments[pguid];
      // let name = person.fullName + (ctx.T.isBlank(person.providerType) ? "" : `, ${ person.providerType }`);
      // let heading = { Name: name}
      // arSheet.push(heading)
      arSheet = arSheet.concat(ar);

    }
    return arSheet || [];
  }
  //=========================================================
  async function createExcel(objData=[],fileName="Report",options={}) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(objData,options);
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    // fix headers
    // XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Birthday"]], { origin: "A1" });
    // Calculate column width
    worksheet["!cols"] ||= [];
    for (const key of Object.keys(objData[0])) {
      let max_width = 4;
      for (const row of objData) {
        if (!row[key]) continue;
        max_width = Math.max(max_width, String(row[key]).length);
      }
      worksheet["!cols"].push({ wch: max_width });
    }
    XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });

  }
  return false;
}
//********************************************************************************************************
_U.eraseAssignments = async function () {
  let ctx = _U.ctx;

  let options = {};
  let props = {
    textOk: "Erase",
    title: "Erase Schedule",
    minHeight: 300,
    message: <>
      <DateRange startDate={ctx.schedule.info.startDate} endDate={ctx.schedule.info.endDate}
        returnData={(d) => options = d}
      />
      <Box>"Locked" assignments will not be erased</Box>
    </>
  }
  let ok = await ctx.T.dlg(props);
  if (!ok) return;

	let day1 = ctx.T.doy(options.startDate);
  let dayN = ctx.T.doy(options.endDate);
  for (let dayNo = day1; dayNo <= dayN; dayNo++) try {
    for (const activity of ctx.schedule.activities) try {
      ctx.schedule.assignments[activity.guid] ||= {};
      ctx.schedule.notes[activity.guid] ||= {};
      ctx.schedule.notes[activity.guid][dayNo] ||= {};
      let isLocked = !! ctx.schedule.notes[activity.guid][dayNo].locked;
      if (!isLocked) {
        ctx.schedule.assignments[activity.guid][dayNo] = "";
        ctx.schedule.notes[activity.guid][dayNo] = {};
      }
    } catch(err) {}
  } catch(err) {}
}
//********************************************************************************************************
_U.makeSchedule = async function () 
{
  let ctx = _U.ctx;

  let options = {};
  let props = {
    textOk: "Create Schedule",
    handleOk: () => { return ctx.T.readInputs(".divOptions")},
    title: "Make Schedule",
    message: <div class="divOptions">
      <DateRange startDate={ctx.schedule.info.startDate} endDate={ctx.schedule.info.endDate}
        returnData={(d) => options = d}
      />
      <FormControlLabel control={<Switch defaultChecked name="fillEmptyOnly" />} label="Fill only empty slots" />
      <Stack>
        <FormGroup>
        <Box sx={{m:0,p:0,mt:2}} component="h4">Schedule the folowing Duties only:</Box>
        {ctx.schedule.activities.map((activity, index) => <FormControlLabel control={<Checkbox value={true} color="success"
          defaultChecked
          name={activity.guid} />} key={activity.guid} label={activity.name} />)}
        </FormGroup>
      </Stack>
    </div>
  }
  let data = await ctx.T.dlg(props);
  if (data === false) return;
  
  await ctx.T.wait(0.25); // allow current dialog to be removed
  let loading = ctx.T.loading("Making Schedule ...")
  await ctx.T.wait(1);  // allow loading dialog to show

	let day1 = ctx.T.doy(options.startDate) || ctx.T.doy(ctx.schedule.info.startDate);
	let dayN = ctx.T.doy(options.endDate) || ctx.T.doy(ctx.schedule.info.endDate);
  let year = ctx.schedule.info.startDate.substring(0, 4);

  let activityCount = 0, assignmentsMade = 0, nooneFound = 0;
  
  for (let pass = 1; pass <= 2; pass++) {
    for (let dayNo = day1; dayNo <= dayN; dayNo++) {
      let dow = _U.dayOfWeek(year, dayNo);
      if (pass == 1 && [1,2,3,4].includes(dow)) continue;  // pass 1 does weekends only.  So weekends get priority
      for (const activity of ctx.schedule.activities) {
        if (!data[activity.guid]) continue;
        if (!_U.needsFilling(activity, year, dayNo)) continue;
        if (data.fillEmptyOnly === "on" && _U.isFilled(activity, dayNo)) continue;
        if (_U.isLocked(activity, dayNo)) continue;
        let counts = _U.countCalls();
        ctx.schedule.assignments[activity.guid] ||= {};
        let nextP = _U.nextPerson(activity, dow, dayNo, counts);
        if (!nextP) {
          ctx.schedule.assignments[activity.guid][dayNo] = false;
          nooneFound++;
          continue;
        }
        // console.log("Needs", activity.name, "person",nextP.name);

        ctx.schedule.assignments[activity.guid][dayNo] = nextP.guid;
        
        if (activity.consecutiveDays == "friSatSun" && dow==5) {
          if (_U.needsFilling(activity, year, 1+dayNo)) ctx.schedule.assignments[activity.guid][1+dayNo] = nextP.guid;
          if (_U.needsFilling(activity, year, 2+dayNo)) ctx.schedule.assignments[activity.guid][2+dayNo] = nextP.guid;
        }
        if (activity.consecutiveDays == "satSun" && dow==6) {
          if (_U.needsFilling(activity, year, 1+dayNo)) ctx.schedule.assignments[activity.guid][1+dayNo] = nextP.guid;
        }
        if (activity.consecutiveDays == "fiveDays" && [1, 2, 3, 4, 5].includes(dow)) {
          for (let dd = dow; dd < 6; dd++) {
            let tdayNo = dd - dow + dayNo;
            if (!_U.needsFilling(activity, year, tdayNo)) continue;
            ctx.schedule.assignments[activity.guid][tdayNo] = nextP.guid;
          }
        }
        if (activity.consecutiveDays == "sevenDays" && [1,2,3,4,5].includes(dow)) {
          for (let dd = dow; dd < 8; dd++) {
            let tdayNo = dd - dow + dayNo;
            if (!_U.needsFilling(activity, year, tdayNo)) continue;
            ctx.schedule.assignments[activity.guid][tdayNo] = nextP.guid;
          }
        }
      }
    }
  }
  loading.unmount();
  ctx.T.alert(`Finished<br>${nooneFound} slots could not be filled and have been highlighted in red`, "Make Schedule");

}
//********************************************************************************************************
_U.needsFilling = function (activity, year, dayNo)
{
  let ctx = _U.ctx;
  let isHoliday = _U.isHoliday(dayNo);
  try {
    let dow = _U.dayOfWeek(year, dayNo);
    if (dow === false) return false;
    // if (!ctx.T.isBlank(ctx.schedule.assignments[activity.guid][dayNo])) return false;    // may want to implement "locked" later
    if (isHoliday) {
      if (activity.holidays) return true; else return false;
    } 
    if(activity[weekDays[dow]]) return true;
  } catch(err) {}

	return false;
}
//********************************************************************************************************
_U.staffOptions = function (activity, doy, dow = false)
{
  let ctx = _U.ctx;
  let options = [];
  try {
    ctx.schedule.staff.forEach(person => { if (available(person)) options.push(person)}); 
  } catch (err) { }
  return options;

  function available(person) {
    if (!person.canDo[activity.guid]) return false;
    if (_U.onVacation(person, doy)) return false;

    try { if ([0,1,2,3,4,5,6].includes(dow) && !person[weekDays[dow]]) return false; } catch(err) {}
    return true;
  }
}
//********************************************************************************************************
_U.nextPerson = function (activity, dow, dayNo, counts)
{
  let ctx = _U.ctx;
  let options = _U.staffOptions(activity, dayNo, dow);
  let available = [];
  let demoted = [];
  for (const person of options) {
    let [fits, demote] = _U.fits(activity, person, dayNo, options, dow);
    if (!fits) continue;
    if (demote) demoted.push(person); else available.push(person);
  }
  available.push(...demoted);

  available.sort((p1, p2) => {    // sort by counts
    try {
      let p1Count = 0, p2Count = 0;
      let obj1 = counts.byActivity[activity.guid][p1.guid];
      let obj2 = counts.byActivity[activity.guid][p2.guid];
      if (_U.isHoliday(dayNo) && activity.equalHolidays) {
        p1Count += obj1.holiday; p2Count += obj2.holiday
      } else if ([1, 2, 3, 4, 5].includes(dow)) {
        if (dow == 5 && activity.equalFridays) { p1Count += obj1[dow]; p2Count += obj2[dow]; }
        else if( activity.equalWeekdays) [1, 2, 3, 4, 5].forEach(d => { p1Count += obj1[d]; p2Count += obj2[d]; });
      } else if ([6, 0].includes(dow)) {
        if (activity.equalSatSun) { p1Count += obj1[dow]; p2Count += obj2[dow]; }
        else if (activity.equalWeekends) [6, 0].forEach(d => { p1Count += obj1[d]; p2Count += obj2[d]; });
      }
      if (p1Count == 0) p1Count = obj1.total;
      if (p2Count == 0) p2Count = obj2.total;
      return p1Count - p2Count;
    } catch (err) { return 0 }
  });
  // console.log("available", available);
  return available[0];

}
//********************************************************************************************************
_U.fits = function (activity, person, dayNo, dow=false)		// Check for sameday / nextday activities
{
  let ctx = _U.ctx;
  ctx.reasons ||= {};
  ctx.reasons[activity.guid] ||= {}
  ctx.reasons[activity.guid][dayNo] ||= {}
  ctx.reasons[activity.guid][dayNo][person.name] ||= {}
  let reasons = ctx.reasons[activity.guid][dayNo][person.name];
  if(!dow) dow = _U.dayOfWeek(false, dayNo);
  
  // Enforce daysApart
  for (let dd = dayNo - 1; dd >= dayNo - activity.daysApart && dd > 0; dd--) {
    if (ctx.schedule.assignments[activity.guid][dd] == person.guid) {
      let dow2 = _U.dayOfWeek(false, dd);
      if ((activity.consecutiveDays == "friSatSun" && [0, 5, 6].includes(dow) && [0, 5, 6].includes(dow2))
        || (activity.consecutiveDays == "satSun" && [0, 6].includes(dow) && [0, 6].includes(dow2))
        || (activity.consecutiveDays == "fiveDays" && [1, 2, 3, 4, 5].includes(dow) && [1, 2, 3, 4, 5].includes(dow2))
        || (activity.consecutiveDays == "sevenDays" &&  [0, 1, 2, 3, 4, 5, 6].includes(dow) && [0, 1, 2, 3, 4, 5, 6].includes(dow2))    // add same week logic
      ); else {
        reasons["Required Interval"] = `Fit failed due to conflict with ${activity.name} ${dayNo-dd} days ago`;
        return [false, false];
      }
    }
  }
  // reasons["Required Interval"] = `No conflict with ${activity.name} over past ${activity.daysApart} days`;

  let sameDay = [], prevDay = [], nextDay=[];
  for (const actguid in ctx.schedule.assignments) {
    try { if (ctx.schedule.assignments[actguid][dayNo] == person.guid) sameDay.push(actguid)} catch (err) { }
    try { if (ctx.schedule.assignments[actguid][dayNo-1] == person.guid) prevDay.push(actguid)} catch (err) { }
    try { if (ctx.schedule.assignments[actguid][dayNo+1] == person.guid) nextDay.push(actguid)} catch (err) { }
  }
  
  // console.log(sameDay, prevDay, activity.sameDay);
  for (const actguid of sameDay) {
    if (actguid == activity.guid) continue;
    let a = _U.activityFromGUID(actguid);
    if (!a.sameDay) continue;
    if (!a.sameDay[activity.guid]) {
      reasons["Same-Day"] = `Fit failed due to same day conflict with ${a.name}`;
      return [false, false];
    }
  }
  // reasons["Same-Day"] = `No SameDay conflict`;
  let demote = sameDay.length > 0;
  for (const actguid of prevDay) {
    if (actguid == activity.guid) continue;
    let a = _U.activityFromGUID(actguid);
    if (!a.nextDay) continue;
    if (!a.nextDay[activity.guid]) {
      reasons["Previous-Day"] = `Fit failed due to previous day conflict with ${a.name}`;
      return [false, demote];
    }
  }
  // reasons["Previos-Day"] = `No Previous-Day conflict`;
  
  for (const actguid of nextDay) {
    if (!activity.nextDay) continue;
    if (actguid == activity.guid) continue;
    if (!activity.nextDay[actguid]) {
      reasons["Next-Day"] = `Fit failed due to next day conflict with ${_U.activityFromGUID(actguid).name}`;
      return [false, false];
    }
  }
  // reasons["Next-Day"] = `No Next-Day conflict`;
  
	// Fail consecutive weekends, regardless of activity
  let weekendConflict = false;
  try {
    let d1, d2, d3, d4;
    if (dow == 6) { d1 = dayNo - 7; d2 = dayNo - 6; d3 = dayNo + 7; d4 = dayNo + 8; }
    if (dow == 0) { d1 = dayNo - 8; d2 = dayNo - 7; d3 = dayNo + 6; d4 = dayNo + 7; }
    let days = [d1, d2, d3, d4];
    days.forEach(dd => {
      for (let activity in ctx.schedule.activities) {
        if (ctx.schedule.assignments[activity.guid][dd] == person.guid) weekendConflict = true;
      }
    });
  } catch (err) { }
  if (weekendConflict) reasons["Adjoining Weekend"] = "Fit failed due to being on-call on an adjoining weekend";
  return [true, demote];    //[fits,make low priority]
}
//********************************************************************************************************
_U.isHoliday = (function () {     // immediately executing function simulates static functionality
  var holidays = _U.calcHolidays();
  return function (dayNo) {
    if (!Array.isArray(holidays) || holidays.length <= 0) holidays = _U.calcHolidays();
    return holidays.includes(Number(dayNo));
  }  
})();
//********************************************************************************************************
_U.isFilled = function(activity, dayNo) {
  let ctx = _U.ctx;
  let out = false;
  try {out = !! ctx.schedule.assignments[activity.guid][dayNo]} catch(err) {}
  return out;
}
//********************************************************************************************************
_U.isLocked = function(activity, dayNo) {
  let ctx = _U.ctx;
  let out = false;
  try {out = !! ctx.schedule.notes[activity.guid][dayNo].locked} catch(err) {}
  return out;
}
//********************************************************************************************************
_U.onVacation = function (person, dayNo) {
  let ctx = _U.ctx;
  let out = false;
  try {out = !! ctx.schedule.vacations[dayNo].includes(person.guid)} catch(err) {}
  return out;
}
//********************************************************************************************************
_U.dayOfWeek = function (year,dayNo)
{
  let ctx = _U.ctx;
  try {
    if(!year) year = ctx.schedule.info.startDate.substring(0, 4);
    var currDate = new Date(year,0,dayNo);
    return currDate.getDay();
  } catch (err) { }
  return false;
}
//********************************************************************************************************
_U.doyRange = function () {
  let ctx = _U.ctx;
	let day1 = ctx.T.doy(ctx.schedule.info.startDate);
  let dayN = ctx.T.doy(ctx.schedule.info.endDate);
  return [day1,dayN];
}
//********************************************************************************************************
_U.countCalls = function (startDate,endDate)
{
  let ctx = _U.ctx;
  let outObj = {
    byActivity: {}, byPerson: [], payments: {}, byWeekday: []};

	let day1 = ctx.T.doy(startDate) || ctx.T.doy(ctx.schedule.info.startDate);
	let dayN = ctx.T.doy(endDate) || ctx.T.doy(ctx.schedule.info.endDate);
  let year = ctx.schedule.info.startDate.substring(0, 4);

  // Create skeleton
  for (const activity of ctx.schedule.activities) {
    outObj.byActivity[activity.guid] ||= {};
    let obj = outObj.byActivity[activity.guid];
    for (const p of ctx.schedule.staff) {
      if(p.guid) obj[p.guid] ||= { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, holiday:0, total: 0 };  // days of week 0-6
    } 
  }

  // Fill data
  let persons = new Set();
  for (const activity of ctx.schedule.activities) {
    // if (activity.guid) continue;
    outObj.byActivity[activity.guid] ||= {};
    let obj = outObj.byActivity[activity.guid];
    
    ctx.schedule.assignments[activity.guid] ||= {};
    for (const dayNo in ctx.schedule.assignments[activity.guid]) {
      if (dayNo < day1 || dayNo > dayN) continue;
      let pguid = ctx.schedule.assignments[activity.guid][dayNo];
      if (ctx.T.isBlank(pguid)) continue;
      persons.add(pguid);
      addPayLine(pguid, activity, dayNo);
    
      let dow = _U.dayOfWeek(year, dayNo);
      if (dow === false) continue;

      obj[pguid] ||= { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, holiday:0, total: 0 };  // days of week 0-6
      obj[pguid][dow]++;
      obj[pguid]["total"]++;
      if(_U.isHoliday(dayNo)) obj[pguid]["holiday"]++;
    }
  }

  persons.forEach(pguid => {
    let row = { Name: _U.personFromGUID(pguid).name };
    for (const actguid in outObj.byActivity) {
      let act = _U.activityFromGUID(actguid);
      row[act.name] = outObj.byActivity[actguid][pguid] ? outObj.byActivity[actguid][pguid].total : 0;
    }
    outObj.byPerson.push(row);
  });

  // Fill byWeekday
  for (const actguid in outObj.byActivity) {
    let act = _U.activityFromGUID(actguid);
    let headingCell = { t: "s", v: act.name, s: { font: { bold: true, sz: 12 } } };
    let row = { name:headingCell }; weekDays3.forEach(d => row[d] = d); row.holiday = "Holidays"; row.total = "Total";
    outObj.byWeekday.push(row);
    let totalsRow = {name:"Total"}
    persons.forEach(pguid => {
      let p = _U.personFromGUID(pguid);
      if (typeof p != "object") return;
      if (!p.canDo[actguid]) return;
      let row = { name: p.fullName };
      [0, 1, 2, 3, 4, 5, 6, "holiday", "total"].forEach(dow => row[isNaN(dow) ? dow : weekDays3[dow]] = outObj.byActivity[actguid][pguid][dow]);
      Object.keys(row).forEach(key => totalsRow[key] = (totalsRow[key] || 0) + (isNaN(row[key]) ? "" : Number(row[key])));
      outObj.byWeekday.push(row);
    });
    outObj.byWeekday.push(totalsRow);
    outObj.byWeekday.push({});
  }

  return outObj;
  
  function addPayLine(pguid, activity, dayNo) {
    let person = _U.personFromGUID(pguid);
    if (ctx.T.isBlank(person)) return;
    
    let name = person.fullName + (ctx.T.isBlank(person.providerType) ? "" : `, ${ person.providerType }`);
    let rateType = "stdRate";
    let hours = activity.hours;
    try {
      let notes = ctx.schedule.notes[activity.guid][dayNo];
      if (Number(notes.hours) > 0) hours = notes.hours;
      rateType = notes.rate;
    } catch (err) { }
    hours = Number(hours) || 0;
    let rate = Number(activity[rateType]) || 0;
    let payment = rate * Number(hours);
    if (payment <= 0) return;

    let [_, date] = ctx.T.dateFromDoy(dayNo, year);
    try { rateType = rateType.charAt(0).toUpperCase() + rateType.slice(1, -4) } catch (err) { }
    let payLine = { Name: name, Shift: activity.name, Date: date, Hours: hours, "Rate Type": rateType, Rate: rate, Payment: payment };
    outObj.payments[pguid] ??= [];
    outObj.payments[pguid].push(payLine);
  }
}
//********************************************************************************************************
// Date.prototype.dayOfYear = function() {									// Jan 1 is day 1 not zero
// 	var onejan = new Date(this.getFullYear(),0,1);
// 	return Math.ceil((this - onejan) / 86400000)+1;
// }
//********************************************************************************************************
_U.replicateWeek = async function () {
  let ctx = _U.ctx;
  let data = await dlgReplicateWeek(ctx);

  let [firstDay, lastDay] = _U.doyRange();
  let year = ctx.schedule.info.startDate.substring(0, 4);
  
  for (let doy2 = data.destStart, doy1 = data.srcStart; doy2 <= data.destEnd; doy2++, doy1++) {
    if (data.alignDays) {
      let dow2 = ctx.T.dowFromDoy(doy2, year);
      let doySearch = 0;
      for (; ; doy1++) {
        if (doy1 > data.srcEnd) {doy1 = data.srcStart; doySearch++}
        let dow1 = ctx.T.dowFromDoy(doy1, year);
        if (dow1 === dow2) break;
        if (doySearch > 1) break;   // No valid day of week
      }
      if (doySearch > 1) continue;   // No valid day of week.  Leave this day blank
    } else if (doy1 > data.srcEnd) doy1 = data.srcStart;

    if (doy1 < firstDay || doy1 > lastDay) continue;
    if (doy2 < firstDay || doy2 > lastDay) continue;

    for (let activity of ctx.schedule.activities) {
      ctx.schedule.assignments[activity.guid] ||= {};
      ctx.schedule.notes[activity.guid] ||= {};
      try { ctx.schedule.assignments[activity.guid][doy2] = ctx.schedule.assignments[activity.guid][doy1] } catch (err) { }
      try { ctx.schedule.notes[activity.guid][doy2] = ctx.schedule.notes[activity.guid][doy1] } catch (err) { }
    }

  }
}
//********************************************************************************************************
_U.delegate = async function (name = "", dataIn = {}) {
  let ctx = _U.ctx;
  let myRole = ctx.T.myRole || {};
  let dataOut = false;
  let labels = {
    edit: "Full Edit / Update",
    // switch: "Switch Call Assignments",
    delete: "Delete Schedule",
    publish: "Publish Schedule",
    grant: "Grant Access to Others",
    reporting: "Run Reports",
    paging: "Use Paging Service",
  }
  let showWarning = false;

  let props = {
    textOk: "Grant/Update Access",
    title: `Access to '${name}'`,
    minHeight: 300,
    extraButtons: [{ label: "Remvoe All Access", class: "w3-red", func: () => { dataOut = "remove"}}],
    handleOk: () => {
      dataOut = Object.assign(dataIn, ctx.T.readForm("formElem"));
      if (!ctx.T.isEmail(dataOut.email)) {
        ctx.T.alert("Please enter a valid email");
        return false;
      }
    },
    message:
      <form id="formElem">
        <TextField fullWidth disabled={dataIn.email?true:false} size="small" variant="outlined" sx={{ my: 2 }} name="email" value={dataIn.email} label="User ID (email) of the Recepient"/>
        <FormGroup sx={{ ml: 10, pb:2 }}>
          {Object.keys(myRole).map(key => {
            let label = myRole[key] ? labels[key] : false;
            if (label) return (<FormControlLabel control={<Checkbox defaultChecked={dataIn[key] ? true : false} name={key} value={1} />} label={label} key={key} />);
            else if(label === false) showWarning = true;
          })} 
        </FormGroup>
        {showWarning && <Box className="w3-text-blue" p={3}>* This list is limited to the privileges you may delegate (same as yours)</Box>}
      </form>
  }
  let ok = await ctx.T.dlg(props);
  if(ok) try {
    dataOut.email = dataOut.email.toLowerCase();
  } catch (err) { dataOut = false}
  return dataOut;
}
//********************************************************************************************************
_U.getViewTime = async function (rows) {
  let ctx = _U.ctx;
  let msgNoList = "";
  var needed = false;
  try {
    Object.entries(rows).map(([msgNo, row]) => {
      if (!row.timeRead) {
        if(msgNo) msgNoList += msgNo + ", ";
        needed = true;
      }
    });
    if(msgNoList.length >= 2) msgNoList = msgNoList.substring(0,msgNoList.length-2);
  } catch(err) {}
  if (!needed) return rows;
  
  let [ar, _] = await ctx.T.formPost("https://pagerapp.amclogic.com/pager3.php", { action: "viewTime", msgNoList: msgNoList });
  try {
    let tzone = new Date().getTimezoneOffset();
    ar.map((obj, nn) => {
      if (obj?.viewed === "1"); else return;
      let key = Number(obj.msgNo);
      if (rows[key]); else return;
      let dt = new DateObject(obj.viewTime);
      dt.minute -= tzone;
      rows[key].timeRead = _U.logTimeRead(key,dt);
    });
  } catch (err) { }
  return rows;
}
//********************************************************************************************************
_U.logTimeRead = function (id,dateObj) {
  let ctx = _U.ctx;
  let timeRead = dateObj.format("MM/DD/YYYY HH:mm");
  ctx.T.post("updateOne", { collection: "Message", query: { _id: String(id) }, doc: { timeRead: timeRead } });
  return timeRead;
}
//********************************************************************************************************
_U.test = async function ()
{
  let ctx = _U.ctx;
  let [result, _] = await ctx.T.post("test");
  console.log(result, _);
}
//********************************************************************************************************
function DateRange(props) {
  let [data, setData] = useState({ startDate: props.startDate, endDate: props.endDate });

  //========================================
  function handleChange(event, name) {
    if (name) {
      data[name] = event.format("YYYY-MM-DD");
    } else {
      event.stopPropagation();
      data[event.target.name] = event.target.value;
    }
    setData({ ...data });
    props.returnData(data);
  }
  //========================================
  props.returnData(data);
  return (<>
    <Stack direction="Row" mx={4} my={3} justifyContent="center">
      <Box sx={{mx:2}}>Start Date:
        <Calendar value={data["startDate"]} onChange={d => handleChange(d, "startDate")}
          minDate={props.startDate} maxDate={props.endDate}
        />
      </Box>
      <Box sx={{mx:2}}>End Date:
        <Calendar value={data["endDate"]} onChange={d => handleChange(d, "endDate")}
          minDate={props.startDate} maxDate={props.endDate}
        />
      </Box>
    </Stack>
    <Box sx={{ mt: 2 }}>{props.note}</Box>

  </>);
}
//********************************************************************************************************
async function dlgReplicateWeek(ctx,data={})
{
  let dates = {};
  function handleChange(ar,prefix) {
    if (Array.isArray(ar) && ar.length == 2) {
      dates[`${prefix}Start`] = ctx.T.doy(ar[0].format("YYYY-MM-DD"));
      dates[`${prefix}End`] = ctx.T.doy(ar[1].format("YYYY-MM-DD"));
    }
  }
  let props = {
    title: `Replicate Assignments`,
    handleOk: () => data = {...ctx.T.readForm("dlgForm"),...dates},
    message: <>
      <form id="dlgForm">
        <Stack direction="row" sx={{ m: 2 }}>
          <Box sx={{ width: 150 }}>From</Box>
          <Calendar range rangeHover name="from" minDate={ctx.schedule.info.startDate} maxDate={ctx.schedule.info.endDate} onChange={d=>handleChange(d,"src")}  />
        </Stack>
        <Stack direction="row" sx={{ m: 2 }}>
          <Box sx={{ width: 150 }}>To</Box>
          <Calendar range rangeHover name="from" minDate={ctx.schedule.info.startDate} maxDate={ctx.schedule.info.endDate} onChange={d=>handleChange(d,"dest")}  />
        </Stack>
        <FormControlLabel control={<Checkbox value={true} defaultChecked={true} name="alignDays" />} label="Align Weekdays during replication" />
      </form>
    
    </>
  }
  await ctx.T.dlg(props);
  return data;
}
//********************************************************************************************************
function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
//********************************************************************************************************
export default _U;